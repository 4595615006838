import React from 'react';
import { Box, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@mui/material';

const styles = {
    media: {
        backgroundPosition: "top right",
    }
};

const CardComponent = ({ image, title, description, buttons }) => {
    return (
        // Modification suggérée : Ajouter un style `objectPosition` à `CardMedia` pour aligner l'image en haut (y=0).
        // Cela permet de positionner l'image en haut de son conteneur, en évitant qu'elle ne soit centrée verticalement.

        <Card
            raised={true}
            style={{
                height: "100%",
                display: 'flex',
                flexDirection: 'column',
                background: 'white'
            }}>
            <CardActionArea>
                <CardMedia
                    component="img"
                    alt="image"
                    height="250"
                    style={{
                        ...styles.media,
                        objectPosition: 'top' // Aligne l'image en haut (y=0)
                    }}
                    image={image}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" color="primary" component="div" fontWeight={700}>
                        {title}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {description}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <Box style={{
                flexGrow: 1
            }}></Box>
            <CardActions>
                <Box sx={{
                    marginLeft: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end'
                }}>
                    {buttons != null ? buttons.map(button => button) : ''}
                </Box>
            </CardActions>
        </Card>

    );
}

export default CardComponent;
