import React from 'react';
import Hero from './Hero';
import Grid from '@mui/material/Grid2';
import { useNavigate } from 'react-router-dom';
import CardComponent from './CardComponent';
import { Button } from '@mui/material';
import Footer from './Footer';
import { Phone, Mail, MessageCircle, ArrowRight } from 'lucide-react';
import { clientReferences, enterpriseReferences, ownerEmail, ownerPhone, servicesManagement } from '../data/constants';
import WhyUsComponent from './WhyUsComponent';
import RecruitmentProcess from './RecruitmentProcess';
import CompleteReferenceComponent from './CompleteReferenceComponent';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

const PersonnalManagement = () => {
    const navigate = useNavigate();

    const handlePhoneCall = () => window.location.href = `tel:${ownerPhone}`;
    const handleEmail = () => window.location.href = `mailto:${ownerEmail}`;
    const handleWhatsApp = () => window.open(`https://wa.me/${ownerPhone}`, '_blank');

    return (
        <>
            <Hero />
            <section className="max-width" style={{
                marginBlock: '2rem',
            }}>
                <h2 className='' style={{
                    fontSize: '1.5rem'
                }}>Nos services liés au management du personnel</h2>
            </section>
            <section className='max-width' style={{
                marginBottom: '2rem',
            }}>
                <Grid container spacing={3}>
                    {servicesManagement.map((service, index) => (
                        <Grid onClick={() => {
                            navigate(service.path);
                        }} size={{ xs: 12, md: 4, sm: 6 }} key={index}>
                            <CardComponent image={service.image} title={service.title} description={service.description} buttons={[
                                <Button size="small" onClick={handlePhoneCall} className="primary-btn">
                                    <PhoneIcon size={16} /> Appeler
                                </Button>,
                                <Button size="small" onClick={handleEmail} className="primary-btn">
                                    <EmailIcon size={16} /> Email
                                </Button>,
                                <Button size="small" onClick={handleWhatsApp} className="primary-btn">
                                    <WhatsAppIcon size={16} /> WhatsApp
                                </Button>
                            ]} />
                        </Grid>
                    ))
                    }
                </Grid>
            </section>
            <section className="max-width" style={{
                marginBlock: '2rem',
            }}>
                <WhyUsComponent forRH={true} />
            </section>
            <section className="max-width" style={{
                marginBottom: '2rem',
            }}>
                <RecruitmentProcess forRH={true}/>
            </section>
            <section className="max-width" style={{
                marginBlock: '2rem',
            }}>
                <CompleteReferenceComponent
                    clientReferences={[]}
                    enterpriseReferences={enterpriseReferences.filter(x => x.activity === 'Management du personnel')}
                />
            </section>
            <Footer />
        </>
    );
}

export default PersonnalManagement;
