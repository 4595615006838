import React from 'react';
import { Box, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import '../style/WhyUsComponent.css'

const WhyUsComponent = ({ forRH }) => {
    return (
        <section class="section" id="why-choose-me">
            <div class="why-choose-me">
                <div class="content-image">
                    {
                        forRH ? (
                            <img src={"/assets/images/why_us1.jpeg"} alt="Professionnel en costume présentant devant un tableau blanc" width="500" height="333" />
                        ) : (
                            <img src={"/assets/images/why_us.png"} alt="Professionnel en costume présentant devant un tableau blanc" width="500" height="333" />
                        )
                    }
                </div>
                <div class="content-text">
                    <h2>Pourquoi nous choisir</h2>

                    {
                        forRH ? (
                            <ul class="why-choose-list">
<li><strong>Professionnalisme et Expertise :</strong> Forts de sept années d’expérience et d’une solide maîtrise en management, ainsi que d’une certification en gestion de la paie, nous offrons un service professionnel et fiable, spécifiquement conçu pour répondre aux défis de la gestion du personnel en entreprise.</li>
<li><strong>Personnel Qualifié et fiable :</strong> Nous recrutons du personnel qualifié et adapté aux besoins spécifiques des entreprises, garantissant ainsi une intégration fluide et efficace au sein de vos équipes.</li>
<li><strong>Confiance et Crédibilité :</strong> Grâce à notre expertise en gestion des ressources humaines et notre certification en gestion de la paie, nous vous garantissons un service fiable, essentiel au bon management de votre personnel.</li>
<li><strong>Satisfaction Clients :</strong> Nous proposons des solutions flexibles en gestion des ressources humaines, parfaitement adaptées aux entreprises, tout en étant à l’écoute de vos besoins spécifiques pour assurer votre satisfaction et le succès de votre organisation.</li>
                            </ul>
                        ) : (
                            <ul class="why-choose-list">
                                <li><strong>Professionnalisme et Expertise :</strong> Forts de notre expertise nous assurons un service spécialisé, credible et adapté.</li>
                                <li><strong>Personnel Qualifié, Formé et fiable :</strong> Nous recrutons, formons et mettons à disposition des ménages et en entreprises un personnel compétent, formé et digne de confiance.</li>
                                <li><strong>Confiance et Crédibilité :</strong> Grâce à nos certifications en <strong>House Cleaning Fundamentals et Care of Young Children and Special Needs</strong>, ainsi qu'à notre agrément du Ministère de l’Emploi et de la Formation Professionnelle, nous garantissons un service crédible.</li>
                                <li><strong>Satisfaction Clients :</strong> Nous offrons des solutions de gestion du capital humain flexibles, adaptées aussi bien aux particuliers qu’aux entreprises, tout en étant à l’écoute de leurs besoins spécifiques.</li>
                            </ul>
                        )
                    }

                </div>
            </div>
        </section>
    );
}

export default WhyUsComponent;
