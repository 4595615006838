// src/components/HomePage.js
import React from 'react';
import Header from './Header';
import Hero from './Hero';
import RecommendedProfiles from './RecommendedProfiles';
import Testimonials from './Testimonials';
import Footer from './Footer';
import { profiles } from '../data/constants'
import { Box, Button } from '@mui/material';
import '../style/HomePage.css'
import CardComponent from './CardComponent';
import Grid from '@mui/material/Grid2';
import SummaryReference from './SummaryReference';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
  const navigate = useNavigate();

  const services = [
    {
      title: "Management des ressources humaines",
      description:
        "Optimisez la gestion de vos ressources humaines grâce à nos solutions de management personnalisées. Nous vous accompagnons dans la structuration, la gestion et le développement de votre équipe pour en maximiser l'efficacité et atteindre vos objectifs.",
      image: "/assets/images/management-personnel.jpg",
      path: '/personnal-management'
    },
    {
      title: "Mise à disposition du personnel",
      description:
        "Accédez rapidement à des talents qualifiés pour répondre à vos besoins temporaires ou permanents. Nous vous fournissons des experts dans divers domaines pour renforcer vos équipes et soutenir votre croissance.",
      image: "/assets/images/mise_disposition_personnel.jpg",
      path: '/personnel-disposition'
    },
  ];


  return (
    <Box className="home-page">
      <Hero />
      <section className="company-intro max-width">
        <h2>À propos de notre entreprise</h2>
        <p>Coprodis est une entreprise spécialisée dans le management des ressources humaines et la mise à disposition de personnel. Nous proposons des solutions sur mesure pour les entreprises, en les aidant à gérer efficacement leur capital humain. En parallèle, nous accompagnons également les particuliers en leur fournissant des services adaptés, tels que la mise à disposition du personnel. Notre mission est de répondre aux besoins de nos clients avec un service personnalisé et de qualité.</p>
      </section>
      <section className='max-width'>
        <Grid container spacing={3}>
          {services.map((service, index) => (
            <Grid onClick={() => {
              navigate(service.path);
            }} size={{ xs: 12, md: 6 }} key={index}>
              <CardComponent image={service.image} title={service.title} description={service.description} buttons={[
                <Button size="small" className="primary-btn" onClick={() => {
                  navigate(service.path);
                }}>En savoir +</Button>
              ]} />
            </Grid>
          ))
          }
        </Grid>
      </section>
      <SummaryReference />
      <Footer />
    </Box>
  );
};

export default HomePage;
