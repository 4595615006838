import { ChevronRight, Users, Heart, Eye } from 'lucide-react';
import { Box, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import '../style/ContactUs.css'
import Footer from './Footer';
// import Map from './Map';
import { useEffect } from 'react';
import { ownerPhone } from '../data/constants';
const axios = require('axios');

const ContactUs = () => {

  const submit = (e) => {
    e.preventDefault();
    const loading = document.querySelector('.loading');
    loading.style.display = 'block';

    const name = document.getElementById('name').value;
    const email = document.getElementById('email').value;
    const service = document.getElementById('service').value;
    const message = document.getElementById('message').value;

    // Simuler l'envoi du formulaire (à remplacer par votre logique d'envoi réelle)
    sendEmail(name,email, service, message);
    setTimeout(() => {
      loading.style.display = 'none';
      alert(`Merci ${name} ! Votre demande concernant le service "${service}" a bien été envoyée. Nous vous contacterons bientôt à l'adresse ${email}.`);
      this.reset();
    }, 2000);
  }

  const sendEmail = (name,email, service, message ) => {
    const data = {
      name: 'Nouveau client pour le service : ' + service,
      subject: 'Nouveau client pour le service : ' + service,
      sender: {
        name: 'WebSite',
        email: email
      },
      type: 'classic',
      htmlContent: name + '\n' + message,
      to: [
        {
          "email": "christian.kepya@pategou-consulting.com",
          "name": "Christian"
        }
      ],
      // scheduledAt: '2018-01-01 00:00:01'
    };

    axios.post('https://api.brevo.com/v3/smtp/email', data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        console.log('Campaign sent successfully:', response.data);
      })
      .catch(error => {
        console.error('Error sending campaign:', error.response ? error.response.data : error.message);
      });

  }

  useEffect(() => {
    const sections = document.querySelectorAll('.section');
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.style.animation = 'fadeInUp 0.6s ease-out forwards';
        }
      });
    }, { threshold: 0.1 });

    sections.forEach(section => {
      observer.observe(section);
    });

    return () => {
      // Clean up the observer and event listener
      observer.disconnect();
    };
  }, [])

  const handleWhatsApp = () => window.open(`https://wa.me/${ownerPhone}`, '_blank');


  const handleScrollToForm = () => {
    document.getElementById('contact-form').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Box className="contact_us_page">
      <div class="banner" style={{ backgroundImage: "url(/assets/images/conseil.jpg)" }}>
        <div class="banner-content">
          <h1 className='max-width'>Contactez-nous pour vos besoins en personnel</h1>
          <div class="banner-buttons max-width">
            <button class="banner-button whatsapp-button" onClick={handleWhatsApp}>Contacter sur WhatsApp</button>
            <button class="banner-button form-button" onClick={() => {
              handleScrollToForm()
            }}>Remplir le formulaire</button>
          </div>
        </div>
      </div>


      <div class="container">
        <h2>Nous sommes à votre écoute</h2>

        <ul>
          <li>Cliquez sur le bouton WhatsApp pour nous contacter directement et obtenir une réponse rapide</li>
          <li>Ou remplissez le formulaire ci-dessous pour nous envoyer un email détaillé de votre demande</li>
        </ul>

        <form id="contact-form"  onSubmit={(e) => submit(e)}>
          <label for="name">Nom complet:</label>
          <input type="text" id="name" name="name" required />

          <label for="email">Adresse email:</label>
          <input type="email" id="email" name="email" required />

          <label for="service">Service souhaité:</label>
          <select id="service" name="service" required>
            <option value="">Choisissez un service</option>
            <option value="management">Management du personnel</option>
            <option value="mise-a-disposition">Mise à disposition du personnel</option>
          </select>

          <label for="message">Votre message:</label>
          <textarea id="message" name="message" rows="6" required></textarea>

          <button type="submit" >Envoyer votre demande</button>
        </form>

        <div class="loading">
          <div class="loading-spinner"></div>
          <p>Envoi en cours, veuillez patienter...</p>
        </div>

        <div id='map'>
          {/* <Map /> */}
          <div class="mapouter"><div class="gmap_canvas"><iframe class="gmap_iframe" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Pategou Business Center&amp;t=h&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe><a href="https://ytmp4.one">ytmp4.one</a></div></div>
        </div>
        <script
          src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBewJ2x5COtaqlSdrt8DdKObNP_rRWCEEE&libraries=places,marker,drawing,geometry&loading=async"
          defer
        ></script>
        <script
          src="../scripts/google_map.js"
          defer
        ></script>
      </div>
      <Footer />
    </Box>
  )
}




export default ContactUs;