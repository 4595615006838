// src/components/Hero.js
import React, { useState } from 'react';
import { Box, Typography, TextField, Button, MenuItem } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import SearchIcon from '@mui/icons-material/Search';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { heroImages, services } from '../data/constants';

const Hero = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleSlideChange = (index) => {
    setCurrentSlide(index);
  };

  return (
    <Box sx={{ position: 'relative', height: '25rem', overflow: 'hidden' }}>
      <Carousel
        showArrows={true}
        showStatus={false}
        showThumbs={false}
        infiniteLoop={true}
        autoPlay={true}
        interval={5000}
        onChange={handleSlideChange}
      >
        {heroImages.map((image, index) => (
          <div key={index} style={{ height: '25rem' }}>
            <img src={image.src} alt={image.alt} style={{ width: '100%',  }} />
          </div>
        ))}
      </Carousel>
      <Box
        sx={{
          position: 'absolute',
          top: '0',
          left: '0',
          textAlign: 'center',
          color: 'white',
          background: 'black',
          zIndex: 1,
          opacity: 0.4,
          width: '100%',
          height: '100%'
        }}
      >

      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: '0',
          left: '0',
          textAlign: 'center',
          color: 'white',
          zIndex: 1,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Typography variant="h2" component="h1" gutterBottom sx={{ color: 'white', textShadow: '1px 1px 1px rgba(0,0,0,0.5)' }}>
          {heroImages[currentSlide].title}
        </Typography>
        <Typography variant="h5" gutterBottom sx={{
          color: 'white', textShadow: '2px 2px 4px rgba(0,0,0,0.5)', display: 'block',
        }}>
          {heroImages[currentSlide].description}
        </Typography>
      </Box>
    </Box>
  );
};

export default Hero;