import React, { Fragment, useRef } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import HomePage from './components/HomePage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import ReactDom from "react-dom/client"
import { createRoot } from 'react-dom/client';
import Register from './components/Rejister';
import Dashboard from './components/admin/Dashboard'
import UserProfile from './components/profile'
import AboutUs from './components/AboutUs';
import Services from './components/Services';
import Header from './components/Header';
import './App.css'
import PersonnalManagement from './components/PersonnalManagement';
import PersonnalDisposition from './components/PersonnelDisposition';
import ContactUs from './components/ContactUs';

function App() {
  return (
    <Fragment>
      <head>
        <meta charSet='utf-8' />
        <title>Coprodis</title>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <meta httpEquiv='Content-Type' content='text/html; charset=utf-8' />
        <script src='https://ajax.googleapis.com/ajax/libs/jquery/1.8.3/jquery.min.js' />
        <script
          src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBewJ2x5COtaqlSdrt8DdKObNP_rRWCEEE&libraries=places,marker,drawing,geometry"
          defer
        ></script>
      </head>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Header />}>
              <Route path='/' element={<HomePage />} />
              <Route path='/login' element={<Login />} />
              <Route path='/register' element={<Register />} />
              <Route path='/personnal-management' element={<PersonnalManagement />} />
              <Route path='/personnel-disposition' element={<PersonnalDisposition />} />
              <Route path='/profile' element={<UserProfile />} />
              <Route path='/admin/dashbord' element={<Dashboard />} />
              <Route path='/about_us' element={<AboutUs />} />
              <Route path='/services' element={<Services />} />
              <Route path='/contact_us' element={<ContactUs />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </Fragment>
  );
}


export default App;


const root = createRoot(document.getElementById('root'));
root.render(<App />);