// Modifications à apporter dans src/components/Header.js

import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Box, useTheme, useMediaQuery, Drawer, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Login from "../components/Login"
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import '../style/Header.css'

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation(); // Ajoutez cette ligne
  const navigate = useNavigate();

  const links = [
    {
      title: 'Accueil',
      path: '/'
    },
    {
      title: 'Management RH',
      path: '/personnal-management'
    },
    {
      title: "Mise à disposition du personnel",
      path: '/personnel-disposition'
    },
    {
      title: "Nous contacter",
      path: '/contact_us'
    },
    {
      title: "Qui sommes-nous",
      path: '/about_us'
    }
  ];

  // Ajoutez cette fonction
  const isActive = (path) => {
    return location.pathname === path;
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setMobileMenuOpen(open);
  };

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {
          links.map((text, index) => (
            <ListItem key={text.title} disablePadding>
              <ListItemButton>
                <ListItemText onClick={() => navigate(text.path)} primary={text.title} style={isActive(text.path) ? {
                  color: '#4CAF50'
                } : {
                  color: ''
                }} />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
    </Box>
  );

  return (
    <>
      <AppBar position="fixed" elevation={0} className='app-bar-container'>
        <Toolbar>
          <img src={'/assets/logos/COPODIS_LOGO.jpeg'} alt="Copodis Personnel Logo" style={{ height: 40, marginRight: 16 }} />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: theme.palette.primary.main }}>
            Coprodis
          </Typography>
          {isMobile ? (
            <>
              <IconButton
                color="primary"
                aria-label="menu"
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              >
                <MenuIcon />
              </IconButton>

              <Drawer
                anchor={'right'}
                open={mobileMenuOpen}
                onClose={toggleDrawer(false)}
              >
                {list()}
              </Drawer>
            </>
          ) : <>
            {
              links.map(
                (link, index) => (<Button onClick={() => navigate(link.path)} className={isActive(link.path) ? "active" : 'inactif'}>{link.title}</Button>)
              )
            }
          </>}
        </Toolbar>
      </AppBar>
      <div style={{
        marginTop: "4rem"
      }}>

      </div>
      <Outlet />
    </>
  );
};

export default Header;