import React, { useEffect } from 'react';
import { ChevronRight, Users, Heart, Eye } from 'lucide-react';
import { Box, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import '../style/AboutUs.css'
import Footer from './Footer';

const AboutUs = () => {
  useEffect(() => {
    const sections = document.querySelectorAll('.section');
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.style.animation = 'fadeInUp 0.6s ease-out forwards';
        }
      });
    }, { threshold: 0.1 });

    sections.forEach(section => {
      observer.observe(section);
    });


    return () => {
      // Clean up the observer and event listener
      observer.disconnect();
    };
  }, []);

  return (
    <Box className="about_us_page">
      <div class="banner" style={{ backgroundImage: "url(/assets/images/dispo-du-personnel.jpg)",backgroundPositionY: 0 }}>
        <div class="banner-content" >
          <h1>Coprodis</h1>
          <p class="subtitle">Gerer et fournir un personnel de qualité est notre but ultime</p>
        </div>
      </div>

      <div class="founder">
        <div className='max-width'>
          <div className='image-content'>
            <img src="/assets/images/fotso.jpg" alt="Portrait de la fondatrice, femme souriante et confiante" width="150" height="150" />
          </div>
          <div>
            <h2 className=''>Mot de la Fondatrice</h2>
            <p>Je suis Calvine Duamelle Fotso, Directrice Générale de COPRODIS INTERIM SARL, une agence spécialisée dans le management des ressources humaines et la mise à disposition du personnel fiable et qualifié aussi bien pour les foyers que pour les entreprises. Notre mission est de fournir à nos clients un service personnalisé. Passionnée par le développement humain, je m'efforce à garantir la satisfaction de chaque famille et entreprise que nous accompagnons.</p>
          </div>
        </div>
      </div>

      <div class="mission-values-vision">
        <div className='max-width'>
          <div class="section">
            <div class="section-icon">🚀</div>
            <h2>Notre Mission</h2>
            <p>Assurer la sécurité, la confiance et la satisfaction dans chaque relation de travail que nous établissons avec les familles et les entreprises.</p>
          </div>
          <div class="section">
            <div class="section-icon">💎</div>
            <h2>Nos Valeurs</h2>
            <ul class="value-list">
              <li>⚖️ Éthique</li>
              <li>🛡️ Intégrité</li>
              <li>🤝 Solidarité</li>
              <li>📋 Collaboration</li>
            </ul>
          </div>
          <div class="section">
            <div class="section-icon">🔭</div>
            <h2>Notre Vision</h2>
            <p>Une qualité de service aux standards internationaux pour un groupe panafricain LEADER</p>
          </div>
        </div>
      </div>
      <Footer />
    </Box>
  )
}

export default AboutUs;