// src/components/Footer.js
import React from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import PolicyIcon from '@mui/icons-material/Policy';
import DescriptionIcon from '@mui/icons-material/Description';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid2';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import '../style/Footer.css';

const Footer = () => {
  const handleWhatsApp = () => window.open(`https://wa.me/+237655983890`, '_blank');

  return (
    <Box className='footer' component="footer" sx={{ bgcolor: 'background.paper', py: 6, borderTop: '1px solid', borderColor: 'primary.light' }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item size={{ xs: 12, md: 4, sm: 6 }}>
            <Typography variant="h6" gutterBottom color="primary" className='center-content' >
              Contact
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }} className='center-content'>
              <PhoneIcon color="primary" sx={{ mr: 1 }} />
              <Typography><Button style={{ color: '#000' }} href="tel:+237651938201" className='text'>+237 651 938 201</Button></Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }} className='center-content'>
              <WhatsAppIcon color="primary" sx={{ mr: 1 }} />
              <Typography><Button style={{ color: '#000' }} onClick={handleWhatsApp} className='text'>+237 655 983 890</Button></Typography>
            </Box>

          </Grid>
          <Grid item size={{ xs: 12, md: 4, sm: 6 }}>
            <Typography variant="h6" gutterBottom color="primary" className='center-content'>
              Réseaux sociaux
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }} className='center-content'>
              <EmailIcon color="primary" sx={{ mr: 1 }} />
              <Typography><Button style={{ color: '#000' }} href="mailto:contact@coprodis.net" className='text'>contact@coprodis.net</Button></Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }} className='center-content'>
              <FacebookIcon color="primary" sx={{ mr: 1 }} />
              <Typography><Button style={{ color: '#000' }} href='https://www.facebook.com/profile.php?id=100063931391898' className='text'>coprodis-interim-sarl</Button></Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }} className='center-content'>
              <LinkedInIcon color="primary" sx={{ mr: 1 }} />
              <Typography><Button style={{ color: '#000' }} href='https://www.linkedin.com/company/coprodis-interim-sarl/' className='text'>coprodis-interim-sarl</Button></Typography>
            </Box>
          </Grid>
          <Grid item size={{ xs: 12, md: 4, sm: 6 }} >
            <Typography variant="h6" gutterBottom color="primary" className='center-content'>
              Adresse
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }} className='center-content'>
              <LocationOnIcon color="primary" sx={{ mr: 1, mt: 0.5 }} />
              <Typography className='text'>
                Situé face super U Bali,<br />
                1er Etage de Pategou Business Center
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 5 }}>
          © 2024 Copodis Personnel. Tous droits réservés.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;