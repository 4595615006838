import React from 'react';
import { Box, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import '../style/CompleteReferenceComponent.css'

const CompleteReferenceComponent = ({
    clientReferences,
    enterpriseReferences
}) => {
    return (
        <section id="complete-references">
            <h2>Mes Références</h2>
            <div class="references-container">
                <div class="references">
                    {
                        enterpriseReferences.length > 0 ? (
                            <>
                                <h3>Références Entreprises</h3>
                                <div class="reference-list">
                                    {
                                        enterpriseReferences != null ? enterpriseReferences.map(reference => (
                                            <div class="reference">
                                                <img src={reference.image} alt={reference.name} class="reference-image" width="80" height="80" />
                                                <div class="reference-content">
                                                    <div class="reference-name">{reference.name}</div>
                                                    <div class="reference-name">{reference.localisation}</div>
                                                    <p>{reference.text}</p>
                                                </div>
                                            </div>
                                        )) : ''
                                    }
                                </div>
                            </>
                        ) : ''
                    }

                </div>
                <div class="references">
                    {
                        clientReferences.length > 0 ? (
                            <>
                                <h3>Références Particuliers</h3>
                                <div class="reference-list">
                                    {
                                        clientReferences != null ? clientReferences.map(reference => (
                                            <div class="reference">
                                                <img src={reference.image} alt={reference.name} class="reference-image" width="80" height="80" />
                                                <div class="reference-content">
                                                    <div class="reference-name">{reference.name}</div>
                                                    <div class="reference-name">{reference.localisation}</div>
                                                    <p>{reference.text}</p>
                                                </div>
                                            </div>
                                        )) : ''
                                    }
                                </div>
                            </>
                        ) : ''
                    }

                </div>
            </div>
        </section>
    );
}

export default CompleteReferenceComponent;



