import React from 'react';
import { Box, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import '../style/RecruitmentProcess.css'

const RecruitmentProcess = ({ forRH }) => {
    const stepsRH = [
        {
            title: "Recrutement",
            description:
                "Grâce à nos stratégies de recrutement iNnovante, nous sélectionnons des profils selon vos besoins spécifiques.",
        },
        {
            title: "Mise à disposition du personnel",
            description:
                "Nous fournissons le personnel prêt à exercer chez le client pour des contrats à court ou long terme.",
        },
        {
            title: "Suivi et gestion administrative",
            description:
                "Nous prenons en charge tous les aspects administratifs du personnel que nous mettons à votre disposition, depuis les formalités d'embauche jusqu'à l'établissement des bulletins de paie, en passant par les déclarations sociales. Vous bénéficiez ainsi d'une gestion simplifiée et sans souci.",
        },
    ];
    const stepsHouse = [
        {
            title: "Recrutement",
            description:
                "Grâce à nos stratégies de recrutement innovante, nous sélectionnons notre personnel mis à disposition selon les exigences particulières du client",
        },
        {
            title: "Formation",
            description:
                "Nous offrons des formations adaptées pour améliorer les compétences de notre personnel.",
        },
        {
            title: "Visite médicale et sécurité au travail",
            description:
                "Un référent dédié assure la sécurité et l'état de santé de notre personnel.",
        },
        {
            title: "Mise à disposition du personnel",
            description:
                "Nous fournissons du personnel qualifié , fiable et préalablement formé et prêt à exercer chez le client pour des contrats à court ou long terme.",
        },
        {
            title: "Suivi et gestion administrative",
            description:
                "Nous gérons toutes les facettes administratives de nos employés",
        },
    ];

    return (
        <section class="section" id="recruitment-process">
            <div class="recruitment-process">
                <div class="content-image">
                    {
                        forRH ? (
                            <img src="/assets/images/recrutement2.jpg" alt="Équipe discutant autour d'une table de réunion" width="500" height="333" />
                        ) : (
                            // <div className='image_visualisation'>
                            //     <img src="/assets/images/recrutement1.jpg" alt="Équipe discutant autour d'une table de réunion" />
                            //     <img src="/assets/images/formation1.jpg" alt="Équipe discutant autour d'une table de réunion" />
                            //     <img src="/assets/images/recrutement_2.jpg" alt="Équipe discutant autour d'une table de réunion" />
                            // </div>
                            <video
                            src='/assets/images/recrutement.mp4' controls autoplay loop muted width="100%" height="auto" ></video>
                        )
                    }


                </div>
                <div class="content-text">
                    <h2>Processus de Recrutement</h2>
                    <ol class="process-steps">
                        {
                            (forRH ? stepsRH : stepsHouse).map(step => <li><strong>{step.title} :</strong> {step.description}</li>)
                        }
                    </ol>
                </div>
            </div>
        </section>
    );
}

export default RecruitmentProcess;
